<template>
  <div class="SolutionPages">
    
    <div class="solutionBackground">
      <Header :activedUrl="activedUrl" :background="background"></Header>
      <div class="bigTitle titImg" title="为您提供行业领先解决方案">为您提供行业领先解决方案</div>
      <div class="bigTitle titImg h5Title" title="为您提供行业领先解决方案">为您提供<br>行业领先解决方案</div>
      
      <div class="w1200 toSeeServes">
        <div v-for="(item,i) in toSeeServe" :key="i" class="serveItem" :class="{'yellowAct':item.title == currentServe.title}"  @click="handerIndex(i)">
          <div class="item-title">
            {{ item.title }}
          </div>
          <div class="item-detailTit">
            {{ item.detailTit }}
          </div>
        </div>
      </div>
      
      <div class="toSeeTab-box">
        <div class="toSeeTab">
          <div v-for="(item,i) in toSeeServe" :key="'see'+i" class="tabItem" :class="{'yellowAct':item.title == currentServe.title}"  @click="handerIndex(i)">
            {{item.title }}
          </div>
        </div>
      </div>
      
      <div class="currentServes">
        <div class="currentLeft">
          <div class="currentTit">{{ currentServe.title }}</div>
          <div class="currentDes">{{ currentServe.detailDes }}</div>
          <div><span class="free" @click="toRegister">免费试用</span></div>
        </div>
        <div class="currentRight">
          <img :src="currentServe.detailImg" />
        </div>
      </div>
    </div>

    <div class="criticalData">
      <div class="bigTitle" title="关键数据和成果">行业痛点及解决方案</div>
      <div class="critical-main">
        <div class="critical-item color0" :class="margTop1" >
          <img src="@a/images/solution/yellow.png" alt="小五出海"  class="solutions" />
        </div>
        <div class="critical-item color1" :class="margTop1" id="color1" >
          <div class="cri-tit">{{ currentServe.list[0].title }}</div>
          <div class="cri-des">{{ currentServe.list[0].des }}</div>
        </div>
        <div class="critical-item color2" :class="margTop2" id="color2">
          <div class="cri-tit">{{ currentServe.list[1].title }}</div>
          <div class="cri-des">{{ currentServe.list[1].des }}</div>
        </div>
        <div class="critical-item color3" :class="margTop2">
          <img src="@a/images/solution/blue.png" alt="小五出海"  class="solutions" />
        </div>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Header from '@v/header/index'
import Foot from '@v/foot/index'
import { isMobile } from '@/utils/index'
export default {
  name: 'SolutionPages',
  props: {},
  components: {
    Header, Foot,
  },
  data() {
    return {
      activedUrl:'solution',
      background:'solution',
      toSeeServe:[
        {
          icon:require('@a/images/header/header.png'),
          title:'短视频出海创收',
          detailIcon:require('@a/images/header/header.png'),
          detailTit:'爆款内容，轻松复制海外成功',
          detailDes:'只需轻轻一点，你的视频就能瞬间登陆 YouTube、Facebook、TikTok 等全球热门平台，瞬间开启“霸屏”模式！小五出海通，采用前沿技术，让你的内容轻松跨越国界，无论你是美食博主、旅行达人还是科技极客，都能在这里找到属于你的舞台。还有可视化收益管理工具，提供清晰透明的结算报表，使每一笔收入都清晰可查，让你对自己的财务状况了如指掌。更有专业团队负责结算事宜，确保收益安全无忧看的到！',
          detailImg:require('@a/images/solution/right2.png'),
          list:[
            {
              title:"内容出海没方向，缺乏海外社媒平台运营经验？",
              des:'小五出海通有运营专家策略进行1V1指导，提供全面化数据分析，帮你深入了解内容的表现，不断优化创作方向，为你量身定制个性化推广策略。一键式分发海外社媒平台，最大化帮你的内容提升曝光率和收益潜力，让出海更简单，让收益更可观！',
            },
            {
              title:"内容出海，收益看不到？",
              des:'小五出海通提供可视化收益管理工具，简化流程提高效率，清晰透明的结算报表实时查收，让你的每一笔收入都清晰可查，对自己的财务状况了如指掌。更有专业团队负责结算事宜，确保你的收益安全无忧，既能看到也能收到！',
            },
          ]
        },
        {
          icon:require('@a/images/header/header.png'),
          title:'短剧出海创收',
          detailIcon:require('@a/images/header/header.png'),
          detailTit:'定制化维权、社媒创收策略，助你打造现象级',
          detailDes:'原创不易，守护更需力量。小五出海通深知每一位创作者的心血与梦想，我们配备了专业的版权保护团队和智能版权系统，为你的内容提供从注册、监控到维权的全方位服务。让侵权者无处遁形，让你的创意安全无虞，不再烦恼版权问题，尽情享受创作的乐趣吧！',
          detailImg:require('@a/images/solution/right1.png'),
          list:[
            {
              title:"短剧出海，内容盗版多怎么办？",
              des:'小五出海通配备有专业的版权保护团队和智能版权系统，为你的内容提供从注册、监控到维权的全方位服务。让侵权者无处遁形，让你的创意安全无虞，不再烦恼版权问题，尽情享受创作的乐趣吧！',
            },
            {
              title:"出海问题繁多，解决没头绪，平台反馈没效果？",
              des:'我们提供专业团队服务，直击海外平台经理，及时解决账号问题，让出海高效便捷不头疼！',
            },
          ]
        },
        {
          icon:require('@a/images/header/header.png'),
          title:'影视内容出海创收',
          detailIcon:require('@a/images/header/header.png'),
          detailTit:'一站式本地化服务，让你的作品俘获全球观众',
          detailDes:'高效快节奏的浏览习惯，让长视频越来越不适合在社媒平台上进行广泛传播，为了更符合短视频的流行趋势，小五出海通可以通过技术化手段将你的影视内容制作成更利于传播的短视频，让你的内容在短视频时代继续爆款频出，持续产生内容收益。',
          detailImg:require('@a/images/solution/right3.png'),
          list:[
            {
              title:"长视频在海外平台流量低能改善吗？ ",
              des:'小五出海通提供本土化服务，从内容策划到运营，结合各大平台流量规则，通过技术化手段将你的影视内容制作成更利于传播的短视频，让你的内容在短视频时代继续爆款频出，持续产生内容收益。开启片库剧行业第二春。',
            },
            {
              title:"大量长视频内容在多元海外平台规划无门？",
              des:'小五出海通为你量身定制个性化运营方案，轻松解决规划问题，让你的创收一步到位。出海更轻松！',
            },
          ]
        },
        {
          icon:require('@a/images/header/header.png'),
          title:'IP全球化运营',
          detailIcon:require('@a/images/header/header.png'),
          detailTit:'多元化变现渠道，让你的IP价值最大化',
          detailDes:'小五出海通建立有海外社媒营销矩阵，搭载独家智能优化算法，为你量身定制优化方案，让你的IP价值最大化，轻松提升海外影响力，同时我们挂靠海外头部社媒电商，为你提供多元化变现渠道，将内容、商品创收二合一，简单几步实现全球收益。',
          detailImg:require('@a/images/solution/right4.png'),
          list:[
            {
              title:"如何扩大IP海外影响力？",
              des:'小五出海通建立海外社媒营销矩阵，为你量身定制优化方案，让你的IP价值最大化，轻松提升海外影响力。',
            },
            {
              title:"IP商品在海外平台如何售卖？",
              des:'小五出海通挂靠海外头部社媒电商，将内容、商品创收二合一，简单几步帮你实现全球收益。',
            },
          ]
        },
      ],

      intervalId: null,
      indexs:0,
      scrollTop:0,
      margTop1:'',
      margTop2:'',
      isH5:false,
    }
  },
  watch: {},
  computed: {
    currentServe(){
      return this.toSeeServe[this.indexs]
    }
  },
  methods: {
    checkPosition() {
      this.scrollTop = document.documentElement
        ? document.documentElement.scrollTop
        : document.body.scrollTop

        const screenHeight = window.innerHeight;
        console.log('tops::', this.scrollTop+ screenHeight)
        if (!this.isH5 ) {
          if( this.scrollTop + screenHeight > 1300){
            this.margTop1= 'marginTop'
          }
          if(  this.scrollTop + screenHeight > 1780){
            this.margTop2= 'marginTop'
          }
        }
        if (this.isH5 ){
          var color1 = document.getElementById('color1')
          var color2 = document.getElementById('color2')
          console.log('color::',color1.offsetTop)
          if( this.scrollTop + screenHeight > color1.offsetTop+60){
            this.margTop1= 'marginTop'
          }
          if(  this.scrollTop + screenHeight > color2.offsetTop+60){
            this.margTop2= 'marginTop'
          }
        }
        
    },
    startScroll() {
      if (this.intervalId) return;
      this.intervalId = setInterval(() => {
        
        if (this.indexs ==3) {
          this.indexs = 0
        } else {
          this.indexs = this.indexs +1
        }
      }, 10000); // 滚动速度，可以根据需要调整
      
    },
    pauseScroll() {
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    resumeScroll() {
      this.startScroll();
    },
    handerIndex(i){
      this.indexs = i
      this.margTop1= ''
      this.margTop2=''
    },
    handerServe(value){
      this.currentServe = this.toSeeServe[value]
    },
    toRegister(){
      if(isMobile()){
        window.location.href = '/erwei'
      } else {
       window.location.href = 'https://cr.xiaowutube.com/register'
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.checkPosition()
    })
    this.handerIndex(0)
    this.isH5 = isMobile()
   

  },
  filters: {},
}
</script>

<style scoped lang="scss">
.SolutionPages{
  overflow-x: scroll;
  .solutionBackground{
    padding-top: 70px;
    background-image:  url('@a/images/solution/solutionbk.png');
    background-size: 100% 100%;
  }

  .toSeeServes{
    width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .serveItem{
      flex: 0 0 275px;
      height: 125px;
      border-radius: 14px;
      padding: 20px 24px;
      border: 1px solid #EEEEEE;
      cursor: pointer;
      margin-bottom: 40px;
      .item-title{
        font-family: AlibabaPuHuiTiM;
        font-size: 24px;
        color: rgba(0,0,0,0.8);
        line-height: 33px;
        margin-bottom: 8px;
      }
      .item-detailTit{
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 16px;
        color: rgba(0,0,0,0.4);
        line-height: 22px;
      }
    }
    .yellowAct{
      border-radius: 14px;
      border: 1px solid transparent;
      background-image:  url('@a/images/solution/car.png');
      background-size: 100% 100%;
      .item-title{
        color: #FB9716;
      }
    }
  }
  .toSeeTab-box{
    display: none;
  }
  .currentServes{
    width: 1160px;
    height: 366px;
    background: #FFFFFF;
    box-shadow: 0px 2px 80px 0px rgba(0,0,0,0.04);
    border-radius: 20px;
    margin: 0 auto;
    padding: 50px 80px 40px 80px;
    display: flex;
    justify-content: space-between;
    .currentLeft{
      flex:00 500px;
    }
    .currentRight{
      img{
        display: block;
        width: 360px;
        height: 234px;
      }
    }
    .currentTit{
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 32px;
      color: rgba(0,0,0,0.8);
      line-height: 44px;
      margin-bottom: 30px;
    }
    .currentDes{
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 16px;
      color: #626262;
      line-height: 22px;
      margin-bottom: 30px;
    }
    .free{
      display: inline-block;
      text-align: center;
      font-family: AlibabaPuHuiTiM;
      font-size: 16px;
      color: rgba(0,0,0,0.8);
      cursor: pointer;
      width: 140px;
      height: 40px;
      line-height: 40px;
      background: linear-gradient( 48deg, #FFDD2F 0%, #FFA50C 15%, #FF673F 85%, #FF4F91 100%);
      border-radius: 6px;
      color: #fff;
    }
    .free:hover{
      background: linear-gradient( 48deg, #FFDD2F 0%, #FFA50C 5%, #FF673F 55%, #FF4F91 100%);
    }
  }
    
  .criticalData{
    width: 900px;
    margin: 0 auto;
    margin-bottom: 90px;
    padding-top: 20px;
    .critical-main{
      display: flex;
      justify-content:space-between;
      flex-wrap: wrap;
      .critical-item{
        flex: 0 0 500px;
        border-radius: 16px;
        padding-top: 100px;
        padding-bottom: 40px;
        margin-top: 140px;
        background: url('@a/images/solution/colorBk.png') right top no-repeat;
        background-size: 80px 80px;
        transition: all 0.5s;
        img.solutions{
          display: inline-block;
          width: 230px;
          height: 270px;
        }
        .cri-tit{
          font-family: AlibabaPuHuiTiM;
          font-size: 24px;
          color: rgba(0,0,0,0.8);
          line-height: 33px;
          margin-bottom: 20px;
          margin-top: 8px;
        }
        .cri-des{
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          font-size: 18px;
          color: rgba(0,0,0,0.6);
          line-height: 25px;
        }
        
      }
     
      .color0{
        padding-top: 60px;
        flex: 0 0 400px;
      }
      .color1{
        position: relative;
        .cri-tit{
          color: #FB9716;
        }
      }
      .color1::after{
        content: "";
        position: absolute;
        right: -500px;
        top: 0;
        width: 539px;
        height: 449px;
        background: linear-gradient( 163deg, #E8F6FF 0%, #88C9FF 100%);
        opacity: 0.18;
        filter: blur(50px);
      }
      .color2{
        position: relative;
        .cri-tit{
          color: #3988FF;
        }
      }
      .color2::after{
        content: "";
        position: absolute;
        left: -500px;
        top: 0;
        width: 502px;
        height: 449px;
        background: linear-gradient( 207deg, #FFFDEC 0%, #FFFCEA 19%, #FFEB88 100%);
        opacity: 0.16;
        filter: blur(50px);
      }
      .color3{
        flex: 0 0 400px;
        padding-top: 60px;
        padding-left: 160px;
      }
      .color5{
        .cri-tit{
          color: #00CEA9;
        }
      }
      .marginTop{
        margin-top: -10px;
      }
      
    }
   
  } 
  .h5Title{
   display: none;
  } 
  .titImg::after{
    content: "";
    position: absolute;
    left:50%;
    bottom: -6px;
    background: url('@a/images/solution/titImg.png') center no-repeat;
    width: 204px;
    height: 18px;
    background-size: 100%;
    margin-left: 15px;
  }

  
}

@media screen and (max-width: 750px) {
  .solutionBackground{
    overflow: hidden;
  }
  .SolutionPages {
    .bigTitle{
      height: auto;
    }
    .titImg{
      display: none;
    }
    .h5Title{
      display: block;
      width: 390px;
      height: auto;
      line-height: 40px;
      margin: 0 auto;
      margin-bottom: 40px;
      margin-top: 60px;
    } 
    .toSeeServes {
      display: none;
      .serveItem {
        flex: 0 0 275px;
        height: auto;
        border-radius: 14px;
        padding: 15px 14px 15px;
        border: 1px solid #EEEEEE;
        cursor: pointer;
        margin-bottom: 40px;
        margin: 10px 20px;
      }
    }
    .toSeeTab-box{
      width: 100%;
      overflow-x: auto;
      display: block;
      -ms-overflow-style: none;  /* IE 10+ */
       scrollbar-width: none;  
     
    }
    .toSeeTab-box::-webkit-scrollbar {
      display: none;
    }

    .toSeeTab{
      margin: 0 auto;
      padding: 0 10px;
      display: block;
      white-space: nowrap;
      margin-bottom: 20px;
      margin-top: 20px;
      width: 510px;
      //  width: auto;
      border-bottom: 1px solid rgba(31,35,41,.15);
      .tabItem{
        display: inline-block;
        padding: 0 10px;
        font-family: AlibabaPuHuiTiM;
        font-size: 18px;
        color: rgba(0,0,0,0.8);
        line-height: 50px;
        border-bottom: 2px solid transparent;
        margin-right: 10px;
      }
      .yellowAct{
        color: #FB9716;
        border-bottom: 2px solid #FB9716;
      }
    }
    .currentServes{
      width: auto;
      height: auto;
      background: #FFFFFF;
      box-shadow: 0px 2px 80px 0px rgba(0, 0, 0, 0.04);
      border-radius: 20px;
      margin: 0 auto;
      padding: 20px 20px 10px 20px;
      display: block;
      margin: 0px 20px;
      justify-content: space-between;
      .currentTit{
        font-size: 18px;
        margin-bottom: 0px;
      }
      .currentDes{
        font-size: 14px;
      }
      .currentRight{
        margin-top: 20px;
        text-align: center;
        img {
          display: inline-block;
          width:90%;
          height: auto;
        }
      }
    }

    .criticalData {
      width: auto;
      margin: 0 auto;
      margin-bottom: 10px;
      .color0, .color3{
        display: none;
      }
      .critical-main{
        display: block;
        .critical-item{
          flex: 0 0 500px;
          border-radius: 16px;
          padding-top: 0px;
          padding-bottom: 40px;
          margin: 140px 20px 0px;
          .cri-tit{
            font-size: 22px;
          }
          .cri-des{
            font-size: 16px;
          }
         
        }
        .color1::after{
          display: none;
        }
        .marginTop{
          margin-top: 0px;
        }
      } 
      
    }

    .titImg::after{
      content: "";
      position: absolute;
      left: auto;
      right: 89px;
      bottom: -8px;
      background: url('@a/images/solution/titImg.png') center no-repeat;
      width: 164px;
      height: 18px;
      background-size: 100%;
      margin-left: 0px;
    }

  }
  
  
}

</style>
